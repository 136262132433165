import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { formatNumberWithCommas } from '../../utils'

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
  },
  thumbnail: {
    width: 70
  }
}

function SimpleTable(props) {
  const { classes, data } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} padding='dense'>
        <TableHead>
          <TableRow>
            <TableCell>Post</TableCell>
            <TableCell align='left'>Likes</TableCell>
            <TableCell align='left'>Comments</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(n => {
            return (
              <TableRow key={n.id}>
                <TableCell align='left'>
                  <img className={classes.thumbnail} src={n.thumbnailUrl} alt='post_thumbnail'></img>
                </TableCell>
                <TableCell align='left'>{formatNumberWithCommas(n.likes)}</TableCell>
                <TableCell align='left'>{formatNumberWithCommas(n.comments)}</TableCell>
                <TableCell component='th' scope='row'>
                  {new Date(n.timestamp * 1000).toLocaleDateString()}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array
};

SimpleTable.defaultProps = {
  data: []
}

export default withStyles(styles)(SimpleTable);

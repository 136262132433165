import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga'

import Landing from './Landing'
import Loading from './Loading'
import Report from './Report/'
import Tips from './Tips/'

import {
  Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import * as ROUTES from '../constants/routes'

const history = createBrowserHistory()
history.listen((location) => {
  ReactGA.pageview(location.pathname)
})

class App extends Component {
  render () {
    return (
      <Router history={history}>
        <Fragment>
          <Switch>
            <Redirect exact from={ROUTES.ROOT} to={ROUTES.LANDING} />
            <Route exact path={ROUTES.LANDING} component={Landing} />
          </Switch>
          <Route exact path={ROUTES.LOADING} component={Loading} />
          <Route exact path={ROUTES.REPORT} component={Report} />
          <Route exact path={ROUTES.TIPS} component={Tips} />
        </Fragment>
      </Router>
    )
  }
}

export default App

import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';

const SimpleLineChart = ({ data }) => {
  
  const formattedData = data.map(n => {
    return {
      name: new Date(n.timestamp * 1000).toLocaleDateString(),
      Likes: n.likes,
      Comments: n.comments
    }
  })

  return (
    // 99% per https://github.com/recharts/recharts/issues/172
    <ResponsiveContainer width='99%' height={320}>
      <LineChart data={formattedData}>
        <XAxis dataKey='name' />
        <YAxis />
        <CartesianGrid vertical={false} strokeDasharray='3 3' />
        <Tooltip />
        <Legend />
        <Line type='monotone' dataKey='Likes' stroke='#82ca9d' />
        <Line type='monotone' dataKey='Comments' stroke='#8884d8' activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

SimpleLineChart.propTypes = {
  data: PropTypes.array
};

SimpleLineChart.defaultProps = {
  data: []
}

export default SimpleLineChart;

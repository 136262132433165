import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    width: 700,
    maxWidth: '100%',
  },
  cta: {
    backgroundColor: theme.palette.primary['50'],
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
      width: 700
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 2,
      width: '100%'
    }
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
  underline: {
    textDecoration: 'underline'
  },
})

const FollowersTips = ({ classes }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              How To Get More Exposure
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              It looks like you are experiencing 1 of 2 things:
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>1. Your following to followers ratio is very high! OR...</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>2. You don't have many followers (aka, no one knows you exist).</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Let’s start with your following to followers ratio. If this ratio is too high, it looks bad and it raises a red flag to Instagram.  
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Also, you may be experiencing a severe lack of exposure. Not many people know you exist on Instagram.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              This is an easy fix.
            </Typography>

            <Typography className={classes.underline} variant='subtitle2' align='center' >
              SOLUTION
            </Typography>
            <Typography variant='h6' align='center' gutterBottom>
              <b>You Need To Get More Followers</b>
            </Typography>

            <Typography variant='body1' align='left' paragraph>
              The first thing you want to do is unfollow the people that you really have no desire in following.  
              Instagram’s technology is so smart now, there is no reason to follow anyone that you don’t have a desire to actually follow and engage with.  
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Many people have tried to follow hundreds of thousands of people they don’t want to follow, ONLY in hopes that these people will follow them back.  
              This may have worked a few years ago, but it doesn’t work now.  
              So go ahead and unfollow people you aren’t going to engage with.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <b>Here are some awesome ways you can attract more targeted followers to your Instagram account:</b>
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              1. Do paid ad giveaways
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              These are awesome because you can give away something simple such as an iPad, or something that you market would love to have.  Then you run ads targeting your market and give them instructions to enter your giveaway.  Your instructions will include them “following” you on Instagram, and then leaving a comment on your profile.  You can spend as little as $5 per day and see amazing results!
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              2. Pay For Shoutouts From Influencers
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              These are amazing as well.  Go find influencers in your niche and ask them if you can pay them to do a shoutout for your profile.  These usually work best when you run a giveaway.  
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              We hope these tips were helpful for you!  
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.cta}>
            <Typography variant='body1' color='inherit' paragraph>
              If you want to truly take your Instagram game to the next level and build a massive audience, make sure to check out our Social Media Rockstar program!
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              href='https://www.paulgetter.com/rockstar-step'
            >
              <SendIcon className={classes.rightIcon} />
              <Typography variant='h6' color='inherit'>
                Yes! I'm Ready To Become A Social Media Rockstar!
              </Typography>
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

FollowersTips.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FollowersTips)
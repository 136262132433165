import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    width: 700,
    maxWidth: '100%',
  },
  cta: {
    backgroundColor: theme.palette.primary['50'],
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
      width: 700
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 2,
      width: '100%'
    }
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
  buttonContainer: {
    textAlign: 'center'
  },
  button: {
    textAlign: 'center'
  },
  underline: {
    textDecoration: 'underline'
  },
  topMargin: {
    marginTop: theme.spacing.unit * 2
  }
})

const WinnerTips = ({ classes }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              99 Club
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              I'm only going to tell this to you since you're here... It is impossible to get a Social Score of 100.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Yep. That's how the algorithm works. So congratulations, you got the highest score possible.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Here are 3 emojis to describe your use of Instagram. Enjoy!
            </Typography>

            <Typography variant='h6' align='left' gutterBottom>
              1. <span role='img' aria-label='emoji'>💸</span>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              You probably make a lot of money from your Instagram. Nice.
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              2. <span role='img' aria-label='emoji'>🔥</span>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Your content is fire. Keep burnin.
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              3. <span role='img' aria-label='emoji'>💥</span>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Boom. That's all there is to say.
            </Typography>

          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.cta}>
            <Typography variant='body1' color='inherit' paragraph>
              This is the Call To Action box where we would normally pitch something to a visitor with a score less than 99. We're not gonna pitch you, but there's a button you can click if you want.
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              href='https://www.paulgetter.com/rockstar-step'
            >
              <SendIcon className={classes.rightIcon} />
              <Typography variant='h6' color='inherit'>
                I Wonder What This Button Does...
              </Typography>
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

WinnerTips.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(WinnerTips)
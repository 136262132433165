import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'

import BuildIcon from '@material-ui/icons/Build'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HelpIcon from '@material-ui/icons/Help'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import Footer from './Footer'

import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import * as ROUTES from '../constants/routes'
import { compose } from 'recompose'
import queryString from 'query-string'

const styles = theme => ({
  '@global': {
    'html, body, #root': {
      height: '100%'
    }
  },
  bodyRoot: {
    padding: 12,
    backgroundImage: 'url("/img/ig_gradient_50_quality.jpg")',
    backgroundColor: '#8243ac',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  copyHeadlineRoot: {
  },
  copyHeadline: {
    textAlign: 'center',
    backgroundColor: 'rgba(156, 39, 176, 0.35)',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing.unit * 3
  },
  subheadline: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  copyBulletRoot: {
  },
  copyBullet: {
    padding: theme.spacing.unit * 3
  },
  inputRoot: {
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    width: 'auto',
    height: '100%',
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  faqRoot: {
  },
  faqHeader: {
    textAlign: 'center',
    backgroundColor: 'rgba(156, 39, 176, 0.35)',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing.unit * 1
  },
  faqQuestions: {
    fontWeight: 700
  },
  underline: {
    textDecoration: 'underline'
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important'
    }
  }
})

class Landing extends Component {
  state = {
    username: ''
  }

  handleChange = ({ target: { value }}) => {
    this.setState({ username: value.toLowerCase() })
  }

  handleErrorClose = () => {
    this.setState({ showError: false })
  }

  handleSubmit = e => {
    e.preventDefault()
    let { username } = this.state
    if (username.startsWith('@')) {
      username = username.slice(1, username.length)
    }
    const destinationUrl = username 
      ? ROUTES.REPORT + '?username=' +  username : ROUTES.REPORT
    this.props.history.push(destinationUrl)
  }

  componentDidMount() {
    const { error } = queryString.parse(this.props.location.search)
    if (error) {
      this.setState({ showError: true })
    }

    ReactGA.pageview(ROUTES.LANDING)
    ReactGA.event({
      category: 'User',
      action: 'Landing'
    })
  }

  render () {
    const { classes, width } = this.props
    const { showError, username } = this.state

    const disabled = username === ''

    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showError}
          autoHideDuration={4000}
          onClose={this.handleErrorClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message-id'>Username not found</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={this.handleErrorClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <div className={classes.bodyRoot}>
          <Grid container justify='center' alignItems='stretch' spacing={24}>

            <Grid item className={classes.copyHeadlineRoot} xs={12}>
              <Paper className={classes.copyHeadline}>
                <Typography variant='h5' color='inherit' align='center' gutterBottom>
                  <b>Want To Become A Social Media Rockstar?</b>
                </Typography>
                <Typography className={classes.subheadline} variant='h6' color='inherit' align='center'>
                  Nerdsio Reveals Your Social Score And Gives You Immediate, Customized Tips You Can Use To Improve Your Influence...
                </Typography>
              </Paper>
            </Grid>

            {width !== 'xs' && <CopyBulletsWrapper />}

            <Grid item className={classes.inputRoot} xs={12} sm={6}>
              <Paper className={classes.input}>
                <Typography component='h1' variant='h6'>
                  Enter Your Insta Username Below To Get A Free Profile Audit
                </Typography>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <FormControl margin='normal' required fullWidth>
                    <InputLabel htmlFor='username'>Insta Username</InputLabel>
                    <Input 
                      id='username' 
                      name='username' 
                      autoComplete='off' 
                      placeholder='username' 
                      autoFocus 
                      value={username}
                      onChange={this.handleChange}
                      startAdornment={<InputAdornment position='start'>@</InputAdornment>}
                    />
                  </FormControl>
                  <Button
                    className={classes.submit}
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={disabled}
                    onClick={this.handleSubmit}
                  >
                    Generate Report
                  </Button>
                </form>
              </Paper>
            </Grid>

            {width === 'xs' && <CopyBulletsWrapper />}

            <Grid item className={classes.desktopOnly} xs={false} sm={2}></Grid>
            <Grid item className={classes.faqRoot} xs={12} sm={8}>
              <Paper className={classes.faqHeader}>
                <Typography variant='h5' color='inherit' align='center'>
                  <b>FAQ</b>
                </Typography>
              </Paper>

              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.faqQuestions}>
                    Does Nerdsio access my login info to Insta?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    No! Nerdsio can be used with any public Insta profile without requiring login information.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.faqQuestions}>
                    How does Nerdsio determine my social score?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    The Social Score algorithm is based on follower data, engagement data, posting schedule and other factors. The final score is based on ideal usage in comparison to the Insta algorithm. 
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.faqQuestions}>
                    How does Nerdsio give me "customized" tips?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    In determining your Social Score, your profile is given individual scores in various categories of usage. By comparing these scores to baselines, we determine which area you need the most help in, allowing for customized tips. 
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item className={classes.desktopOnly} xs={false} sm={2}></Grid>
          </Grid>

        </div>
        <Footer />
      </div>
    )
  }
}

const CopyBullets = ({ classes }) => (
  <Fragment>
    <Grid item className={classes.copyBulletRoot} xs={12} sm={6}>
      <Paper className={classes.copyBullet}>
        <Typography variant='subtitle1' align='center' gutterBottom>
          <b>OUR PROPRIETARY SOFTWARE WILL:</b>
        </Typography>
        <List>
          <ListItem alignItems='flex-start'>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Determine your biggest weakness, whether it's lack of engagement, small reach, message to market match, etc..." />
          </ListItem>
          <ListItem alignItems='flex-start'>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary="Give you customized tips (provided instantly) to help you overcome these weaknesses and start seeing results..." />
          </ListItem>
          <ListItem alignItems='flex-start'>
            <ListItemIcon>
              <ThumbUpIcon />
            </ListItemIcon>
            <ListItemText primary="Help breathe life into your IG game..." />
          </ListItem>
        </List>
      </Paper>
    </Grid>
  </Fragment>
)

const CopyBulletsWrapper = withStyles(styles)(CopyBullets)

Landing.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withWidth(),
  withStyles(styles),
  withRouter
)(Landing)

import React from 'react'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  '@global': {
    'a': {
      color: 'white',
      textDecoration: 'none'
    }
  },
  footerRoot: {
    backgroundColor: '#404040',
    color: 'white',
    textAlign: 'center',
    fontSize: 8,
    padding: theme.spacing.unit * 3,
  },
  footerTypography: {
    fontSize: 10
  },
  divider: {
    backgroundColor: 'rgb(255, 255, 255, 0.3)'
  },
  underline: {
    textDecoration: 'underline'
  },
  footerNavigation: {
    fontWeight: 700
  },
  topMargin: {
    marginTop: 16 
  }
})

const Footer = ({ classes }) => (
  <div className={classes.footerRoot}>
    <Typography className={classes.footerTypography} color='inherit' paragraph>
      This site is not a part of the Facebook website or Facebook, Inc.<br/>
      Additionally, this site is not endorsed by Facebook in any way.<br/>
      FACEBOOK is a trademark of FACEBOOK, Inc.
    </Typography>
    <Typography className={classes.footerTypography} color='inherit' paragraph>
      DISCLAIMER: The results (including results) stated above are my personal results as well as those of my clients. Please understand these results are not typical, I’m not implying you’ll duplicate them (or do anything for that matter). I have the benefit of having gone through much training, trial, and error for years, and have an established following and history as a result. The average person who buys any “how to” information gets little to no results. I’m using these references for example purposes only. Your results will vary and depend on many factors…including but not limited to your background, experience, and work ethic.
    </Typography>
    <Typography className={classes.footerTypography} color='inherit' paragraph>
      All business entails risk as well as massive and consistent effort and action. If you’re not willing to accept that, please DO NOT GET THIS PRODUCT. By submitting your email address and phone number on this website, you are authorizing our company to send you informational and promotional messages via email, phone calls, and text messages.
    </Typography>

    <Divider className={classes.divider} variant='middle' />

    <Typography className={classes.topMargin} variant='overline' color='inherit'>
      Need To Contact Support?
    </Typography>
    <Typography variant='caption' color='inherit' paragraph>
      <a className={classes.underline} href='https://www.getnerdhelp.com/get-help'> Click Here To Visit Our Support Desk.</a><br />
      Or Email Us At <a className={classes.underline} href='mailto:support@getnerdhelp.com'>support@getherdhelp.com</a>
    </Typography>

    <Typography variant='caption' color='inherit' paragraph>
      15275 Collier Blvd #201-572 Naples, FL 34119
    </Typography>

    <Grid container>
      <Grid item xs={false} sm={false} md={2}></Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Typography variant='subtitle2' color='inherit'>
          <a className={classes.footerNavigation} href='https://www.paulgetter.com/membership-access-step'>Login</a>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Typography variant='subtitle2' color='inherit'>
          <a className={classes.footerNavigation} href='https://www.paulgetter.com/privacy'>Privacy Policy</a>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Typography variant='subtitle2' color='inherit'>
          <a className={classes.footerNavigation} href='https://www.paulgetter.com/terms-step'>Terms Of Use</a>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Typography variant='subtitle2' color='inherit'>
          <a className={classes.footerNavigation} href='https://www.paulgetter.com/disclaimer'>Disclaimer</a>
        </Typography>
      </Grid>
      <Grid item xs={false} sm={false} md={2}></Grid>
    </Grid>
  </div>
)

export default withStyles(styles)(Footer)

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    width: 700,
    maxWidth: '100%',
  },
  cta: {
    backgroundColor: theme.palette.primary['50'],
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
      width: 700
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 2,
      width: '100%'
    }
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
  buttonContainer: {
    textAlign: 'center'
  },
  button: {
    textAlign: 'center'
  },
  underline: {
    textDecoration: 'underline'
  },
  topMargin: {
    marginTop: theme.spacing.unit * 2
  }
})

const PostingTips = ({ classes }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              How To Improve Your Posting Frequency and Consistency
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              We see that there is a problem with your posting frequency and/or consistency.  Basically you aren’t posting enough.  To really crush it on Instagram and be able to monetize, you’ve got to stay consistent and relevant.  
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              The more you post the better.  But at the end of the day if you can post 4-7 times per week, that will give you a good chance at doing well on Instagram and building the right type of audience and engagement, that will allow you to monetize properly.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <b>Now, let’s talk about the REAL reasons</b> you aren’t posting consistently…
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              From our experience here are the real reasons people don’t post often:
            </Typography>

            <Typography variant='body1' align='left' paragraph>
              <i>1. Maybe you don’t know WHAT to post…</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>2. Maybe you lack ideas for content…</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>3. Maybe you have content ideas, but don’t believe you can make your content look good, with reference to images and/or videos.</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>4. You don’t think you have the time to post consistently.</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>5. You don’t believe that posting consistently will yield the results you want in your business.</i>
            </Typography>

            <Typography className={classes.underline} variant='subtitle2' align='center' >
              SOLUTION
            </Typography>
            <Typography variant='h6' align='center' gutterBottom>
              <b>How To Crush The Content Game</b>
            </Typography>

            <Typography variant='body1' align='left' paragraph>
              Ok, so here are the solutions to the issues above:
            </Typography>

            <Typography variant='h6' align='left' gutterBottom>
              1. Model what others in your market are doing
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              You don’t need to reinvent the wheel.  Find influencers in your space that are getting results and have a great audience.  Study what they are posting about.  Study the topics they are posting about and then model what they are doing.  
            </Typography>

            <Typography variant='h6' align='left' gutterBottom>
              2. Constantly consume new content
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Read books, listen to podcasts, and watch videos.  Basically, you need to be studying and learning every single week.  This is the greatest way to get content ideas.  Listen to podcasts that are related to your business or marketplace for 15 min per day and watch content ideas start to flood in like crazy.
            </Typography>

            <Typography variant='h6' align='left' gutterBottom>
              3. Outsource Outsource Outsource!  
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Listen, if you aren’t good at design, get help.  You may not realize it, but you can get some amazing designers that will design images for you for very inexpensive.  Of course you’ll have to work with them and provide them ideas, but you can get other people to help make your content LOOK awesome.
            </Typography>

            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant='contained'
                color='secondary'
                href='https://www.paulgetter.com/images-step'
              >
                <Typography variant='h6' color='inherit'>
                  OR You Can Hire Our Team To Do All Of Your Instagram Posts For You!
                </Typography>
              </Button>
            </div>

            <Typography className={classes.topMargin} variant='body1' align='left' paragraph>
              We hope these tips were helpful for you!  
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.cta}>
            <Typography variant='body1' color='inherit' paragraph>
              If you want to truly take your Instagram game to the next level and build a massive audience, make sure to check out our Social Media Rockstar program!
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              href='https://www.paulgetter.com/rockstar-step'
            >
              <SendIcon className={classes.rightIcon} />
              <Typography variant='h6' color='inherit'>
                Yes! I'm Ready To Become A Social Media Rockstar!
              </Typography>
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

PostingTips.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PostingTips)
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    width: 700,
    maxWidth: '100%',
  },
  cta: {
    backgroundColor: theme.palette.primary['50'],
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
      width: 700
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 2,
      width: '100%'
    }
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
  underline: {
    textDecoration: 'underline'
  },
})

const EngagementTips = ({ classes }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              How To Improve Engagement
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Wow, your engagement rate is low.  We are going to fix that!
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Ok, so first let me go through a few reasons your engagement rate can be low, and then we’ll give you tips to change this.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>1. You haven’t attracted the right kind of followers to your audience OR you don’t have the right message to market match.</i>
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              <i>2. You may have low quality content and/or media.  This includes your images, videos, as well as the text/captions you are using when you post.</i>
            </Typography>

            <Typography className={classes.underline} variant='subtitle2' align='center' >
              SOLUTION
            </Typography>
            <Typography variant='h6' align='center' gutterBottom>
              <b>Give People A Reason To Engage</b>
            </Typography>

            <Typography variant='body1' align='left' paragraph>
              Ok, so here are the solutions:
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              1. Take Your Captions Seriously
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Start putting more energy and thought into the content you post via text.  Most people treat Instagram as a place to just post pictures and videos.  But the reality is, the text content that you post along with your images and videos is super important.  Not only is it important to your audience, but it is important to the Instagram algorithm.  Make sure you are writing captions that provide value or entertainment to people’s lives.
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              2. Understand your message-to-market match  
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Make sure you know what your audience wants.  If you want to build a real business via Instagram, it is imperative that you aren’t just posting what “you” want to post.  It’s important that you create media (videos/images) that will give value and entertainment to your market.  
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              3. Increase the quality of your media
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              This can be as simple as getting someone on upwork.com or fiverr.com to help you spice up your images and videos.  For instance when we do video posts, we don’t just post our videos.  We create the video, then send it to an outsourcer to turn it into a meme style video with text at the top and then scrolling captions along the bottom, for people that want to watch the video on mute.  You can get meme style videos done for as little as $5 to $10.
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              4. Start incentivizing your followers to engage
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Instagram is about having a conversation with your audience.  Start asking them questions.  Start the conversation and ask them for feedback.  Ask them to comment or tag a friend.  Give them a reason to comment.
            </Typography>

            <Typography variant='body1' align='left' paragraph>
              We hope these tips were helpful for you!  
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.cta}>
            <Typography variant='body1' color='inherit' paragraph>
              If you want to truly take your Instagram game to the next level and build a massive audience, make sure to check out our Social Media Rockstar program!
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              href='https://www.paulgetter.com/rockstar-step'
            >
              <SendIcon className={classes.rightIcon} />
              <Typography variant='h6' color='inherit'>
                Yes! I'm Ready To Become A Social Media Rockstar!
              </Typography>
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

EngagementTips.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EngagementTips)
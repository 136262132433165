import { SHOW_LOGS } from '../constants/logs'

const rp = require('request-promise')
const $ = require('cheerio')

export const analyzeProfile = (username) => {
  const profileUrl = 'https://www.instagram.com/' + username + '/'

  return new Promise((resolve, reject) => {
    rp(profileUrl)
      .then(htmlString => {
        const profileData = getProfileDataFromHtml(htmlString)

        let responseData
        if (profileData.isPrivate || profileData.postsCount === 0) {
          responseData = profileData
        } else {
          const engagementStats = getEngagementStats(profileData)
          const socialScores = getSocialScores(profileData, engagementStats)
          const profileCase = getProfileCase(socialScores)

          responseData = Object.assign(
            profileData, {
              engagement: engagementStats,
              scores: socialScores,
              profileCase
            }
          )
        }

        const response = {
          status: 200,
          data: responseData
        }

        resolve(response)
      })
      .catch(error => {
        if (error.statusCode === 404) {
          resolve({ status: 404 })
        } else {
          reject({error: 'Error retrieving profile info'})
        }
      })
  })
}

const getEngagementStats = (profileData) => {
  const averageLikes = profileData.posts.reduce((previous, current) => previous + current.likes, 0) / profileData.posts.length
  const averageComments = profileData.posts.reduce((previous, current) => previous + current.comments, 0) / profileData.posts.length
  const engagementRateLikes = averageLikes / profileData.followersCount
  const engagementRateComments = averageComments / profileData.followersCount
  const engagementRate = (averageLikes + averageComments) / profileData.followersCount

  return {
    averageLikes,
    averageComments,
    engagementRateLikes,
    engagementRateComments,
    engagementRate 
  }
}

const getSocialScores = (profileData, engagementStats) => {
  // post schedule
  // -- consistency
  // -- frequency
  // number followers
  // number following
  // average likes per post
  // average comments per post
  // engagement rate
  // -- total
  // -- likes only
  // -- comments only

  const postScheduleScore = getPostScheduleScore(profileData.posts)
  const followerRatioScore = getFollowerRatioScore(profileData)
  const engagementScore = getEngagementScore(engagementStats)

  const rawSocialScore = postScheduleScore * followerRatioScore * engagementScore

  const adjustment = 0.3
  const socialScore = 100 * (rawSocialScore + adjustment) / (1 + rawSocialScore + adjustment)

  const adjustedSocialScore = Math.min(socialScore, 100)

  return {
    postScheduleScore,
    followerRatioScore,
    engagementScore,
    socialScore: adjustedSocialScore
  }
}

const getPostScheduleScore = (posts) => {
  const totalPostPeriod = posts[0].timestamp - posts[posts.length - 1].timestamp
  let timeBetweenPosts = []
  posts.forEach((_, index) => {
    if (index === posts.length -1) return
    const difference = posts[index].timestamp - posts[index + 1].timestamp
    timeBetweenPosts.push(difference)
  })

  // Consistency
  const idealConsistencyPeriod = totalPostPeriod / (posts.length - 1)  // Perfect even spacing of posts over posting period
  const consistencyScores = timeBetweenPosts.map(time => {
    if (time < idealConsistencyPeriod) {
      return time / idealConsistencyPeriod
    } else {
      return idealConsistencyPeriod / time
    }
  })

  const consistencyScore = consistencyScores.reduce((previousScore, currentScore) => previousScore + currentScore, 0) / consistencyScores.length

  const ADJUSTMENT_FACTOR_CONSISTENCY = 1
  const adjustedConsistencyScore = ADJUSTMENT_FACTOR_CONSISTENCY * consistencyScore

  // Frequency
  const idealFrequencyPeriod = 3600 * 24 / 1  // 1 post per day

  const averageTimeBetweenPosts = timeBetweenPosts.reduce((previous, current) => previous + current, 0) / (posts.length - 1)

  const frequencyScore = averageTimeBetweenPosts < idealConsistencyPeriod ? (
    averageTimeBetweenPosts / idealFrequencyPeriod
  ) : (
    idealFrequencyPeriod / averageTimeBetweenPosts
  )

  const ADJUSTMENT_FACTOR_FREQUENCY = 1
  const adjustedFrequencyScore = ADJUSTMENT_FACTOR_FREQUENCY * frequencyScore

  return adjustedConsistencyScore * adjustedFrequencyScore
}

const getFollowerRatioScore = (profileData) => {
  // Based on big profiles like Paul and Tai Lopez, 10 followers : 1 following
  const idealFollowerToFollowingRatio = 10 / 1  
  const followerToFollowingRatio = profileData.followersCount / profileData.followingCount
  const followerRatioScore = followerToFollowingRatio / idealFollowerToFollowingRatio

  const ADJUSTMENT_FACTOR = 1
  return ADJUSTMENT_FACTOR * followerRatioScore
}

const getEngagementScore = (engagementStats) => {
  // Based on big profiles like Paul, Tai, Craig, Vince, JC, Brandon Carter
  const idealEngagementRate = 0.03 
  const engagementRate = engagementStats.engagementRate
  const engagementScore = engagementRate / idealEngagementRate

  const ADJUSTMENT_FACTOR = 1
  return ADJUSTMENT_FACTOR * engagementScore
}

const getProfileCase = (socialScores) => {
  // --- TUNING PARAMETERS ---
  const followerRatioScoreThreshold = 1
  const engagementScoreThreshold = 1.6
  const postScheduleScoreThreshold = 0.4
  // -----------------------------------

  const followerRatioBinScore = socialScores.followerRatioScore / followerRatioScoreThreshold
  const engagementBinScore = socialScores.engagementScore / engagementScoreThreshold
  const postScheduleBinScore = socialScores.postScheduleScore / postScheduleScoreThreshold

  if (SHOW_LOGS) {
    console.log('followerRatioBinScore:', followerRatioBinScore)
    console.log('engagementBinScore:', engagementBinScore)
    console.log('postScheduleBinScore:', postScheduleBinScore)
  }

  const weights = [
    { case: 0, name: 'followerRatio', score: socialScores.followerRatioScore, weight: followerRatioBinScore },
    { case: 1, name: 'engagement', score: socialScores.engagementScore, weight: engagementBinScore },
    { case: 2, name: 'postSchedule', score: socialScores.postScheduleScore, weight: postScheduleBinScore }
  ]
  const sortedWeights = weights.sort((a, b) => a.weight - b.weight)
  if (SHOW_LOGS) console.log('sortedWeights:', sortedWeights)

  // Assume best bin match is the one with the lowest score relative to the threshold
  let bestMatch = sortedWeights[0]
  // If every score is low, put into special case where help is needed with everything
  if (sortedWeights.every(item => item.weight < 1)) {
    const everythingCase = { case: 3, name: 'everything' }
    bestMatch = everythingCase
  }
  if (socialScores.socialScore >= 99) {
    const winnerCase = { case: 4, name: 'winner' }
    bestMatch = winnerCase
  }

  if (SHOW_LOGS) {
    console.log('--------')
    console.log('bestMatch.name:', bestMatch.name)
    console.log('--------')
  }

  return bestMatch.case
}

const getProfileDataFromHtml = (html) => {
  const dataBodyPrefix = 'window._sharedData = '
  let rawDataString = $('script', html).get(4).children[0]['data'].trim()
  let startIndex = rawDataString.indexOf(dataBodyPrefix)
  if (startIndex === -1) {
    // Found a case where the HTML returned a different number of script tags
    // Index = 3 is the fallback
    rawDataString = $('script', html).get(3).children[0]['data'].trim()
    startIndex = 0
  }
  const endIndex = startIndex + dataBodyPrefix.length

  const jsonBodyString = rawDataString.substring(endIndex, rawDataString.length - 1)
  const jsonBody = JSON.parse(jsonBodyString)
  const user = jsonBody['entry_data']['ProfilePage'][0]['graphql']['user']

  const profileData = {
    id: getUserId(user),
    isVerified: getIsVerified(user),
    isPrivate: getIsPrivate(user),
    name: getName(user),
    bio: getBio(user),
    profilePicUrl: getProfilePic(user),
    postsCount: getPostsCount(user),
    followersCount: getFollowersCount(user),
    followingCount: getFollowingCount(user),
    highlightReelCount: getHighlightReelCount(user),
    isBusinessAccount: getIsBusinessAccount(user),
    posts: []
  }
  
  const posts = getPosts(user)

  posts.forEach(post => {
    const postData = {
      id: getPostId(post),
      caption: getPostCaption(post),
      type: getPostType(post),
      isvideo: getPostIsVideo(post),
      timestamp: getPostTimestamp(post),
      likes: getPostLikes(post),
      comments: getPostComments(post),
      thumbnailUrl: getThumbnailUrl(post),
    }
    profileData.posts.push(postData)
  })

  return profileData
}

const getUserId = (user) => {
  return user['id']
}

const getIsVerified = (user) => {
  return user['is_verified']
}

const getIsPrivate = (user) => {
  return user['is_private']
}

const getName = (user) => {
  return user['full_name']
}

const getBio = (user) => {
  return user['biography']
}

const getProfilePic = (user) => {
  return user['profile_pic_url']
}

const getPostsCount = (user) => {
  return user['edge_owner_to_timeline_media']['count']
}

const getFollowersCount = (user) => {
  return user['edge_followed_by']['count']
}

const getFollowingCount = (user) => {
  return user['edge_follow']['count']
}

const getHighlightReelCount = (user) => {
  return user['highlight_reel_count']
}

const getIsBusinessAccount = (user) => {
  return user['is_business_account']
}

const getPosts = (user) => {
  return user['edge_owner_to_timeline_media']['edges']
}

const getPostType = (post) => {
  return post['node']['__typename']
}

const getPostId = (post) => {
  return post['node']['id']
}

const getPostIsVideo = (post) => {
  return post['node']['is_video']
}

const getPostTimestamp = (post) => {
  return post['node']['taken_at_timestamp']
}

const getPostCaption = (post) => {
  try {
    return post['node']['edge_media_to_caption']['edges'][0]['node']['text']
  } catch (error) {
    return ''
  }
}

const getPostLikes = (post) => {
  return post['node']['edge_liked_by']['count']
}

const getPostComments = (post) => {
  return post['node']['edge_media_to_comment']['count']
}

const getThumbnailUrl = (post) => {
  return post['node']['thumbnail_src']
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 2,
    height: 40
  },
  textField: {
    display: 'block'
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class DripOptin extends Component {
  state = {
    diagnosis: 0,
    name: '',
    email: '',
    valid: false
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault()
    const { name, email } = this.state
    this.props.handleSubmit(name, email)
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(String(email.trim()).toLowerCase());
    return result
  }

  render() {
    const { name, email } = this.state
    const { classes, isSubmitting } = this.props

    const isEmailFormat = this.validateEmail(email)
    const disabled = name === '' || !isEmailFormat

    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl margin='dense' required fullWidth>
          <InputLabel htmlFor='name'>Name</InputLabel>
          <Input 
            id='name'
            name='fields[full_name]'
            className={classes.textField}
            autoComplete='off'
            value={name}
            onChange={this.handleChange('name')}
            autoFocus
          />
        </FormControl>
        <FormControl margin='dense' required fullWidth>
          <InputLabel htmlFor='email'>Email</InputLabel>
          <Input 
            id='email'
            name='fields[email]'
            autoComplete='off'
            className={classes.textField}
            value={email}
            onChange={this.handleChange('email')}
          />
        </FormControl>
        <Button 
          className={classes.button}
          type='submit'
          variant='contained' 
          color='secondary' 
          disabled={disabled}
          data-drip-attribute='sign-up-button'
          onClick={this.handleSubmit}
          fullWidth
        >
          {isSubmitting ? 
            <CircularProgress size={24} className={classes.buttonProgress} color='inherit' /> 
            : <SendIcon className={classes.rightIcon} />
          }
          {!isSubmitting && 'See Tips'}
        </Button>
      </form>
    )
  }
}

DripOptin.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

export default withStyles(styles)(DripOptin)
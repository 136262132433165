export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const formatNumberWithCommas = (x) => {
  if (x === 0) return 0
  if (!x) return
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const roundToDecimalPlace = (x, numberPlaces) => {
  if (x === 0) return 0
  if (!x) return
  return x.toFixed(numberPlaces)
}


export const getWaitTime = (retryCount) => {
  // Return wait time using exponential back off with slight randomness in milliseconds starting around 100ms
  const max = 2.2
  const min = 1.8
  const base = Math.random() * (max - min) + min
  return Math.pow(base, retryCount) / 1000 * 50
}
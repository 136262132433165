import React, { Fragment } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import SimpleTable from './SimpleTable'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import LockIcon from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'

import withWidth from '@material-ui/core/withWidth'

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'
import fetch from 'isomorphic-fetch'
import classNames from 'classnames'
import ProgressiveImage from 'react-progressive-image'

import Footer from '../Footer'
import Loading from '../Loading'
import SimpleLineChart from './SimpleLineChart'
import DripOptin from './DripOptin'

import * as ROUTES from '../../constants/routes'
import * as API from '../../constants/api'
import { CASE_TO_TIPS_MAP } from '../../constants/cases'
import { SHOW_LOGS } from '../../constants/logs'

import { analyzeProfile } from '../../utils/scrape'
import { formatNumberWithCommas, getWaitTime, roundToDecimalPlace } from '../../utils'

const CLIENT_SIDE_SCRAPING = false
const MAX_ATTEMPTS = 3

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  contentRoot: {
    padding: 12
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  topBanner: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center'
  },
  profile: {
    textAlign: 'center'
  },
  image: {
    width: '100%',
    maxWidth: '150px',
    borderRadius: '50%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    padding: '2px'
  },
  header: {
    fontWeight: 600
  },
  label: {
    color: '#8e8e8e',
  },
  paperScore: {
    textAlign: 'center',
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing.unit * 3
  },
  socialScoreAnnotation: {
    color: '#d6d6d6',
    fontSize: 14
  },
  scoreTop: {
    backgroundColor: green[600],
  },
  scoreMid: {
    backgroundColor: orange[800],
  },
  scoreLow: {
    backgroundColor: red[500],
  },
  engagement: {
    textAlign: 'center'
  },
  engagementCharts: {
    textAlign: 'center'
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    marginTop: theme.spacing.unit * 3
  },
  paperCase: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing.unit * 3
  },
  caseTeaser: {
    textAlign: 'center',
    position: 'relative'
  },
  media: {
    height: 140
  },
  topMargin: {
    marginTop: 20
  },
  teaserOverlay: {
    position: 'absolute',
    padding: 0,
    backgroundImage: 'linear-gradient(rgba(255,255,255,0) 10%, rgba(255,255,255,1) 90%)',
    height: '100%',
    width: '100%',
  },
  teaserLockContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    color: 'black', 
    fontSize: 64,
    opacity: 0.9
  },
  teaserLock: {
    marginTop: 12
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    height: 90,
    width: 90,
    backgroundColor: 'black',
    opacity: 0.2,
    borderRadius: '50%',
    display: 'inline-block'
  },
  mobileOnly: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  dialogButtonContainer: {
    textAlign: 'center',
    width: '100%'
  },
  dialogButton: {
    backgroundColor: blue[500],
    height: 68,
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  bottomPadding: {
    paddingBottom: 12
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
})

class Report extends React.Component {
  state = {
    didFetchData: false,
    isSubmittingDrip: false,
    isDialogOpen: false,
    showLoadingScreen: true,
    username: '',
    profileData: null
  }

  handleDialogSubmit = (name, email) => {
    this.setState({ isSubmittingDrip: true })

    const { profileData } = this.state
    const profileCase = profileData.profileCase ? profileData.profileCase.toString() : 0

    const nameTrimmed = name.trim()
    const emailTrimmed = email.trim()

    const options = { 
      method: 'POST',
      body: JSON.stringify({
        name: nameTrimmed,
        email: emailTrimmed,
        case: profileCase,
        socialScore: profileData.scores ? profileData.scores.socialScore : null
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const endpoint = API.SUBSCRIBE
    return fetch(endpoint, options)
      .then(response => response.json())
      .then(json => {
        if (SHOW_LOGS) console.log('drip response:', json)
        const destinationUrl = ROUTES.TIPS + '?case=' + profileCase
        this.props.history.push(destinationUrl)
      })
      .catch(error => {
        this.setState({ isSubmittingDrip: false })
        console.error(error)
      })
  }

  handleProfileNotFound = () => {
    const destinationUrl = ROUTES.LANDING + '?error=not_found'
    this.props.history.push(destinationUrl)
  }

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false })
  }

  onFinishLoading = () => {
    this.setState({ showLoadingScreen: false })
  }

  scrapeProfile = (username) => {
    const component = this
    analyzeProfile(username).then(response => {
      if (response.status === 404) {
        component.handleProfileNotFound()
      } else {
        const profileData = response.data
        if (SHOW_LOGS) console.log('profileData:', profileData)
        component.setState({ didFetchData: true, profileData }) 
      }
    }).catch(error => {
      console.error(error)
    })
  }

  fetchProfileData = (username) => {
    const options = { method: 'GET' }
    const endpoint = API.ANALYZE_PROFILE + '?username=' + username
    const component = this

    function fetchProfileDataInner(attemptCount) {
      if (attemptCount > MAX_ATTEMPTS) {
        return
      }
      return fetch(endpoint, options)
        .then(response => {
          if (response.status === 404) {
            component.handleProfileNotFound()
            return Promise.reject(404)
          } else if (response.status === 500) {
            const waitTime = getWaitTime(attemptCount)
            setTimeout(
              () => fetchProfileDataInner(attemptCount + 1),
              waitTime
            ) 
          } else {
            return Promise.resolve(response.json())
          }
        })
        .then(json => {
          if (SHOW_LOGS) console.log('profileData:', json)
          component.setState({ didFetchData: true, profileData: json }) 
        })
        .catch(error => {
          console.error(error)
        })
    }
    return fetchProfileDataInner(1)
  }

  componentDidMount () {
    const { username } = queryString.parse(this.props.location.search)
    this.setState({ username })

    if (CLIENT_SIDE_SCRAPING) {
      this.scrapeProfile(username)
    } else {
      this.fetchProfileData(username)
    }

    ReactGA.event({
      category: 'User',
      action: 'ViewContent'
    })
  }

  render() {
    const { classes, width } = this.props
    const { 
      didFetchData, 
      isSubmittingDrip, 
      showLoadingScreen, 
      username, 
      profileData,
      isDialogOpen
    } = this.state

    const showAnalysis = !!profileData && !profileData.isPrivate && profileData.postsCount > 0
    const socialScore = showAnalysis ? profileData.scores.socialScore : null

    return (
      <div className={classes.root}>
        {showLoadingScreen || !profileData ? <Loading didFetchData={didFetchData} onFinishLoading={this.onFinishLoading} /> : (
          <Fragment>
            <div className={classes.contentRoot}>
              <Grid container className={classes.bottomPadding} spacing={24}>
                <Grid item className={classes.profile} xs={12}>
                  <Grid container justify='center' >
                    <Paper className={classes.paper}>
                      <Grid container justify='center' alignItems='center' spacing={8}>
                        <Grid item xs={12}>
                          <Typography variant='h5'>
                            {profileData.name} 
                            {profileData.isVerified && (
                              <Tooltip title='verified' placement='bottom'>
                                <img src='/img/ig_verified_badge_24.png' alt='ig_verified'></img>
                              </Tooltip>
                            )}
                            {profileData.isPrivate && (
                              <Tooltip title='private' placement='bottom'>
                                <LockIcon />
                              </Tooltip>
                            )}
                          </Typography>
                          <Typography className={classes.label} variant='h6'>
                            @{username}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justify='center' alignItems='center' spacing={8}>
                        <Grid item xs={3}>
                          <ProgressiveImage 
                            src={profileData.profilePicUrl}
                            placeholder='/img/ig_profile_placeholder.jpeg'
                          >
                            {src => <img className={classes.image} src={src} alt='ig-profile-avatar' /> }
                          </ProgressiveImage>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.header} variant='h6'>
                            {formatNumberWithCommas(profileData.postsCount)}
                          </Typography>
                          <Typography className={classes.label} variant='subtitle1'>
                            posts
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.header} variant='h6'>
                            {formatNumberWithCommas(profileData.followersCount)}
                          </Typography>
                          <Typography className={classes.label} variant='subtitle1'>
                            followers
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.header} variant='h6'>
                            {formatNumberWithCommas(profileData.followingCount)}
                          </Typography>
                          <Typography className={classes.label} variant='subtitle1'>
                            following
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid> 
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classNames(
                    classes.paperScore, {
                      [classes.scoreTop]: socialScore >= 70,
                      [classes.scoreMid]: socialScore < 70 && socialScore >= 40,
                      [classes.scoreLow]: socialScore < 40
                    }
                  )}>
                    <Typography className={classes.header} variant='h5' color='inherit'>
                      Social Score: {showAnalysis ? Math.floor(socialScore) : 'N/A'}
                      <span className={classes.socialScoreAnnotation}>&nbsp;/ 100</span>
                    </Typography>
                  </Paper>
                </Grid>
                {!showAnalysis ? (
                  <Grid item className={classes.engagement} xs={12}>
                    <Paper className={classes.paper}>
                      <Typography variant='h5'>
                        {profileData.isPrivate && 'Analysis not available for private profiles'}
                        {profileData.postsCount === 0 && 'No posts available to analyze'}
                      </Typography>
                    </Paper>
                  </Grid>
                ) : (
                  <Fragment>
                    <Grid item className={classes.engagement} xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant='h5'>
                          Engagement Analysis
                        </Typography>
                        <Typography className={classes.label} variant='caption' gutterBottom>
                          (based on your most recent 12 posts)
                        </Typography>
                        <Grid container justify='center' alignItems='stretch'>
                          <Grid item xs={4}>
                            <Typography className={classes.header} variant='h6'>
                              {formatNumberWithCommas(roundToDecimalPlace(profileData.engagement.averageLikes, 2))}
                            </Typography>
                            <Typography className={classes.label} variant='caption'>
                              average likes<br />per post
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.header} variant='h6'>
                              {formatNumberWithCommas(roundToDecimalPlace(profileData.engagement.averageComments, 2))}
                            </Typography>
                            <Typography className={classes.label} variant='caption'>
                              average comments<br />per post
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.header} variant='h6'>
                              {roundToDecimalPlace((profileData.engagement.engagementRate * 100), 2)}%
                            </Typography>
                            <Typography className={classes.label} variant='caption'>
                              engagement<br />rate
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item className={classes.dialogButtonContainer} xs={12}>
                      <Fab 
                        variant='extended' 
                        color='secondary' 
                        aria-label='open-dialog' 
                        className={classes.dialogButton}
                        onClick={() => this.handleDialogOpen()}
                      >
                        <LockOpen className={classes.rightIcon} />
                        <Typography variant='subtitle1' color='inherit'>
                          Click To Unlock Profile Tips
                        </Typography>
                      </Fab>
                    </Grid>

                    <Grid item className={classes.engagementCharts} xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant='h5' gutterBottom>
                          Engagement By Post Over Time
                        </Typography>
                        <Typography component='div' className={classes.chartContainer}>
                          <SimpleLineChart data={!!profileData.posts ? profileData.posts.slice().reverse() : []} />
                        </Typography>
                        <div className={classes.tableContainer}>
                          <SimpleTable data={profileData.posts}/>
                        </div>
                      </Paper>
                    </Grid>
                  </Fragment>
                )}
                <Grid item className={classes.caseTeaser} xs={12}>
                  <Card className={classes.paper}>
                    <CardActionArea onClick={this.handleDialogOpen}>
                      <div className={classes.teaserOverlay}>
                        <div className={classes.teaserLockContainer}>
                          <LockIcon className={classes.teaserLock} fontSize='inherit' />
                          <span className={classes.circle}></span>
                        </div>
                      </div>
                      <Paper className={classes.paperCase}>
                        <Typography variant='h5' color='inherit'>
                          Profile Diagnosis: <br className={classes.mobileOnly} /> <b>{'profileCase' in profileData ? CASE_TO_TIPS_MAP[profileData.profileCase].name : 'Not Available'}</b>
                        </Typography>
                      </Paper>
                      <Typography className={classes.topMargin} variant='h6' gutterBottom>
                        How To Improve Your Profile
                      </Typography>
                      <img
                        className={classes.media}
                        src='https://2w7s3v2763ko2csnhl2smcvg-wpengine.netdna-ssl.com/wp-content/uploads/2018/11/Instagram.jpg'
                        alt='Instagram Logo'
                      ></img>
                    </CardActionArea>
                  </Card>
                </Grid>

                <Grid item className={classes.dialogButtonContainer} xs={12}>
                  <Fab 
                    variant='extended' 
                    color='secondary' 
                    aria-label='open-dialog' 
                    className={classes.dialogButton}
                    onClick={() => this.handleDialogOpen()}
                  >
                    <LockOpen className={classes.rightIcon} />
                    Click To Unlock Profile Tips
                  </Fab>
                </Grid>

                <Dialog 
                  open={isDialogOpen} 
                  onClose={this.handleDialogClose} 
                  className={classes.dialog} 
                  aria-labelledby='optin-dialog-title'
                  maxWidth='xs'
                  scroll={width === 'xs' ? 'body' : 'paper'}
                >
                  <DialogTitle>
                    Unlock Profile Tips
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Enter Your Name & Email Below To Unlock Tips To Improve Your Insta Profile
                    </DialogContentText>
                    <DripOptin 
                      handleSubmit={this.handleDialogSubmit} 
                      isSubmitting={isSubmittingDrip} 
                    /> 
                  </DialogContent>
                </Dialog>
              </Grid>
            </div>
            <Footer />
          </Fragment>
        )}
      </div>
    )
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired
}

export default compose(
  withStyles(styles),
  withWidth(),
  withRouter
)(Report)
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core';
import queryString from 'query-string'

import FollowersTips from './FollowersTips'
import EngagementTips from './EngagementTips'
import PostingTips from './PostingTips'
import EverythingTips from './EverythingTips'
import WinnerTips from './WinnerTips'

import Footer from '../Footer'

import { CASE_TO_TIPS_MAP } from '../../constants/cases'

const styles = theme => ({
  rootContainer: {
    flowGrow: 1
  },
  root: {
    flexGrow: 1,
    padding: '12px',
  },
  paperCase: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing.unit * 3
  },
  mobileOnly: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
})

class Tips extends Component {
  state = {
    profileCase: 0
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    var { case: profileCase } = queryString.parse(this.props.location.search)
    profileCase = profileCase || 0
    this.setState({ profileCase })

    ReactGA.event({
      category: 'User',
      action: 'Lead'
    })
  }

  render () {
    const { profileCase } = this.state
    const { classes } = this.props

    const currentCase = CASE_TO_TIPS_MAP[profileCase]

    return (
      <div className={classes.rootContainer}>
        <div className={classes.root}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Paper className={classes.paperCase}>
                <Typography variant='h5' color='inherit'>
                  Profile Diagnosis: <br className={classes.mobileOnly} /> <b>{currentCase.name}</b>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='center'>
                {{
                  0: <FollowersTips />,
                  1: <EngagementTips />,
                  2: <PostingTips />,
                  3: <EverythingTips />,
                  4: <WinnerTips />
                }[profileCase]}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}

Tips.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Tips)

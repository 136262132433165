import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    width: 700,
    maxWidth: '100%',
  },
  cta: {
    backgroundColor: theme.palette.primary['50'],
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
      width: 700
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit * 2,
      width: '100%'
    }
  },
  rightIcon: {
    marginRight: theme.spacing.unit
  },
  buttonContainer: {
    textAlign: 'center'
  },
  button: {
    textAlign: 'center'
  },
  underline: {
    textDecoration: 'underline'
  },
  topMargin: {
    marginTop: theme.spacing.unit * 2
  }
})

const EverythingTips = ({ classes }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              The Power of Instagram
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Ok, so it seems that you haven’t really made an effort to take advantage of the opportunity that exists on Instagram.  We realize that could be for many different reasons.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              Here’s what you should know.  Over 1 Billion people are now using Instagram.  It’s where people’s eyeballs are.
            </Typography>
            <Typography variant='body1' align='left' paragraph>
              If you want to grow your business or influence online, you NEED to be on Instagram.  Right now, Instagram is kinda like Facebook was a few years ago.  It’s offering amazing opportunity to get crazy good engagement as well as very inexpensive advertising.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify='center'>
          <Paper className={classes.cta}>
            <Typography variant='body1' color='inherit' paragraph>
              If you’d like us to take you by the hand and show you how to start crushing it on Instagram (including monetizing your audience), then make sure to check out our Social Media Rockstar program!
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              href='https://www.paulgetter.com/rockstar-step'
            >
              <SendIcon className={classes.rightIcon} />
              <Typography variant='h6' color='inherit'>
                Yes! I'm Ready To Become A Social Media Rockstar!
              </Typography>
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

EverythingTips.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EverythingTips)
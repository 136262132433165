import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'

const styles = theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '80%',
    maxWidth: '500px',
    color: theme.palette.primary.contrastText,
    textAlign: 'center'
  },
  spacing: {
    marginTop: theme.spacing.unit
  }
})

class LinearBuffer extends React.Component {
  state = {
    username: '',
    completed: 0,
    buffer: 10,
    loadingText: 'Locating Profile...',
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 500)
    const { username } = queryString.parse(this.props.location.search)
    this.setState({ username })
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  progress = () => {
    const { completed } = this.state
    const { didFetchData, onFinishLoading } = this.props
    if (completed > 100 && didFetchData) {
      this.setState({ completed: 100, buffer: 0, loadingText: 'Report Complete' })
      clearInterval(this.timer)
      onFinishLoading()
    } else {
      if (completed < 33) {
        this.setState({ loadingText: 'Locating Profile...' })
      } else if (completed >= 33 && completed < 66) {
        this.setState({ loadingText: 'Analyzing Data...' })
      } else if (completed >= 66 && completed < 100) {
        this.setState({ loadingText: 'Generating Report...' })
      } else if (completed >= 100 && !didFetchData) {
        this.setState({ loadingText: 'Generating Report...' })
      }
      const diff = Math.random() * 20
      const diff2 = Math.random() * 20
      this.setState({ completed: completed + diff, buffer: completed + diff + diff2 })
    }
  }

  render() {
    const { classes } = this.props
    const { username, completed, buffer, loadingText } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.loading}>
          <Typography
            variant='h5'
            color='inherit'
            gutterBottom
          >
            {loadingText}
          </Typography>
          <LinearProgress 
            className={classes.spacing}
            color="secondary" 
            variant="buffer" 
            value={completed} 
            valueBuffer={buffer} 
          />
          <Typography
            className={classes.spacing}
            variant='subtitle1'
            color='inherit'
            gutterBottom
          >
            Username: {username}
          </Typography>
        </div>
      </div>
    )
  }
}

LinearBuffer.propTypes = {
  classes: PropTypes.object.isRequired,
  didFetchData: PropTypes.bool.isRequired,
  onFinishLoading: PropTypes.func.isRequired
}

export default compose(
  withStyles(styles),
  withRouter
)(LinearBuffer)
export const CASE_TO_TIPS_MAP = {
  0: {
    name: 'You Need More Exposure',
    description: 'Primary issue: follower Ratio is bad, need more followers'
  },
  1: {
    name: 'Your Engagement Rate Is Low',
    description: 'Primary issue: low engagement on posts. Need to improve engagement.'
  },
  2: {
    name: 'Your Posting Frequency And/Or Consistency Is Low',
    description: 'Primary issue: posting schedule is bad, due to lack of posting or inconsistency.'
  },
  3: {
    name: 'You Need An Instagram Overhaul',
    description: 'Primary issue: low scores in all areas. Everything needs help.'
  },
  4: {
    name: '💸 🔥 💥',
    description: 'Social Score = 99, which is the highest. You don\'t need any help.',
    tips: [
      {
        name: '💸',
        description: 'You probably make a lot of money from your Instagram. Nice.'
      },
      {
        name: '🔥',
        description: 'Your content is fire. Keep burnin.'
      },
      {
        name: '💥',
        description: 'Boom. That\'s all there is to say.'
      }
    ]
  }
}